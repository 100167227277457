@import 'src/styles/colors';
@import 'src/styles/styles';
@import 'src/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 3;
  position: relative;
  height: $header-height;
  width: 100%;

  &.keynote {
    position: fixed;
  }

  &.smallHeader {
    padding: 0 30px;
    height: $small-header-height;
  }

  @media (max-width: $min-width) {
    padding: 0 20px;
  }
}

.headerGroup {
  display: flex;
  align-items: center;

  .buttonNextWrapper {
    margin-right: 15px;
    .buttonNext {
      padding: 7px 10px;
    }
  }
}

.membersWrapper {
  color: $light-gray;
  text-align: center;
  margin-right: 40px;
  text-transform: uppercase;
  @include useFont(12px, 14px);

  .numbers {
    color: $green2;
    @include useFont(18px, 20px);
  }
}