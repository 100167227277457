@import 'src/styles/colors';

@mixin useFont(
  $font-size: 16px,
  $line-height: 19px,
  $font-weight: normal,
) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin useBlueGradient(
  $green-size: 100%,
  $blue-size: 100%,
) {
  background: linear-gradient(264.42deg, $light-green $green-size, $dark-purple2 $blue-size);
  filter: blur(8px);
}

@mixin useYellowPurpleGradient(
  $yellow-size: 100%,
  $purple-size: 100%,
) {
  background: linear-gradient(264.42deg, $yellow $yellow-size, $dark-purple2 $purple-size);
}

@mixin usePurpleYellowGradient(
  $purple-size: 100%,
  $yellow-size: 100%,
) {
  background: linear-gradient(264.42deg, $dark-purple2 $purple-size, $yellow $yellow-size);
}

@mixin useYellowGreenGradient(
  $green-size: 100%,
  $orange-size: 100%,
  $blue-size: 100%,
  $yellow-size: 100%,
) {
  background: linear-gradient(23.61deg, $green1 $green-size, $orange-yellow $orange-size, $light-blue $blue-size, $yellow $yellow-size);
}

@mixin useOverflow() {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 1px;
    opacity: 0;
  }
}

@mixin useButtonAnimation() {
  content: "";
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 100px;
  @include useBlueGradient(13.95%, 71.53%);
}

@mixin useBlueShadow () {
  box-shadow: 0px 0px 6px $light-blue3;
}