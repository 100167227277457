@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/styles';

.successWrapper {
  height: calc(100% - 2 * #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $min-width) {
    height: calc(100%);
  }
}

.successContainer {
  width: 100%;
  display: grid;
  justify-items: center;
  grid-auto-rows: min-content;
  color: $white;
  text-align: center;
  padding: 0 30px;
  @include useOverflow();
  pointer-events: all;

  * {
    z-index: 1;
    flex-shrink: 0;
  }

  .voicesButton {
    margin-top: 40px;
  }

  .shareContainer {
    width: 310px;
    height: 150px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .share {
      height: 40px;
    }

    .voices_social {
      margin-top: 15px;
    }
  }

  .shareButton {
    margin-top: 80px;
    height: 40px;
    @media (max-width: $min-width) {
      margin-top: 40px;
    }
  }

  .titleContainer {
    margin-top: 25px;
    text-transform: uppercase;

    .title {
      @include useFont(34px, 40px);
      letter-spacing: 4.2px;
      text-align: center;
    }
  }

  .description {
    margin-top: 16px;
    @include useFont(17px, 24px);
    max-width: 850px;
  }

  .buttonWrapper {
    width: 220px;
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: calc(50% - 110px);

    @media (max-width: $min-width) {
      position: inherit;
      margin-bottom: 40px;
    }
  }
}


.buttonNext {
  text-transform: uppercase;
}