@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';

@keyframes visible_change {
  
}

.questionWrapper {
  height: calc(100% - 2 * #{$header-height});
  position: relative;
  color: $white;

  &.mobileWrapper {
    height: calc(100% - #{$header-height});
  }

  &.smallWrapper {
    height: calc(100% - 2 * #{$small-header-height});
  }
}

.disabledWrapper {
  transition: opacity 2s;
  opacity: 0;
  height: 100%;
  pointer-events: none;


  &.visibleWrapper {
    transition: opacity 2s;
    opacity: 1;
  }
}