@import 'src/styles/mixins';

.mobileDescription {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .crumb {
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-width: 768px;
    width: 100%;
    padding: 0 15px;
  }
}

.questionWrapper {
  margin-top: 280px;
  z-index: 2;
  height: 100%;
  width: 100%;
}