@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/variables';

@keyframes modalClose {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes modalOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exploreModal {
    width: 100%;
    height: calc(100% - 2 * #{$header-height});
    animation: modalOpen 2s forwards;

    @media (max-height: $min-height) {
      height: calc(100% - 2 * #{$small-header-height});
      // height: 100vh;
    }

  &.exploreModal_close {
    animation: modalClose 2s forwards;
    pointer-events: none;
    button {
      pointer-events: none;
    }
  }

  .modal_container {
    width: 100%;
    height: 100%;
  }

  .modal_closeButton {
    text-align: right;

    .closeExplore {
      color: $white;
      text-transform: uppercase;
      margin-right: 40px;
      display: flex;
      align-items: center;

      p {
        @include useFont(15px, 15px);
        margin: 0;
        margin-right: 15px;
      }
    }
  }
}