@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/styles';

.audio {
  display: inline-block;
}

.recordWrapper {
  position: relative;
  background-color: rgba(12, 14, 30, 0.8);
  width: 100%;
  max-width: 529px;
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  text-align: left;

  &.mobileRecord {
    flex-direction: column;
    text-align: center;

    button  > div {
      margin: 0;
    }

    .description {
      margin-top: 10px;
    }

    .timerContainer {
      display: flex;
      justify-content: center;
    }
  }

  .description {
    @include useFont(12px, 16px);

    .record_error {
      color: $red;
    }

    .tutorial {
      color: $white;

      p {
        font-weight: 700;
        margin: 0;
        display: inline-block;
        margin-right: 3px;
      }
    }

    .rules {
      color: $light-gray;
    }
  }

  .timerContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;

    .deleteButton {
      margin-left: 33px;
      color: $light-gray;
      @include useFont(12px, 16px);
      cursor: pointer;
    }

    .timer {
    @include useFont(24px, 16px);
    color: $green2;

    &.timerError {
      color: $red;
    }
  }
  }
}

.recordAnimation {
  position: absolute;
  right: -10px;
  top: -50px;
}