@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/styles';

button {
  .filterButton {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    @include useFont(12px, 19px);
    letter-spacing: 4px;
    background-color: $dark-purple1;
    border: 1px solid $white;
    border-radius: 100px;
    padding: 0 38px;
    height: 48px;
    transition: background-color .4s;
    text-transform: uppercase;
  
    p {
      margin-left: 20px;
    }
  
    svg {
      stroke: $white;
    }
  
    &::after {
      content: "";
      z-index: -1;
      position:absolute;
      width:93%;
      height: 93%;
      top: 3.5%;
      left: 3.5%;
      @include useYellowPurpleGradient(13.95%, 71.53%);
      filter: blur(8px);
      border-radius: 100px;
      transform: rotate(-1.5deg);
    }
  
    &:hover {
      background-color: $light-purple;
      box-shadow: 0 0 12px rgba(255,255,255, 0.01);
      &::after {
        @include usePurpleYellowGradient(13.95%, 71.53%);
      }
    }
  
    &:active {
      color: $light-purple2;
      background-color: $dark-purple1;
  
      svg {
        stroke: $light-purple2;
      }
    }
  }

  &:focus {
    .filterButton {
      @include useBlueShadow();
      box-shadow: 0 0 12px rgba(255,255,255, 0.01);
      &::after {
        opacity: 0.5;
      }
    }
  }
}

.shareButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  text-transform: uppercase;
  padding: 0 14px;
  height: 40px;
  border-radius: 100px;
  @include useFont(12px, 19px);
  letter-spacing: 2px;
  transition: background-color .4s;

  p {
    margin-left: 14px;
  }

  &:hover {
    background-color: $light-purple;
  }

  &:active {
    border-radius: 85px;
    height: 35px;
    padding: 0 12px;
    @include useFont(10px, 16px);
    letter-spacing: 1.7px;

    p {
      margin-left: 12px;
    }

    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.navigatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .buttonNextWrapper {
    margin: 0 15px;

    .buttonNext {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}