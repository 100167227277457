@import 'src/styles/colors';

@keyframes closeModal {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@mixin panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $menu-color;
    opacity: 0;
    transform: scaleY(0);
    transition: all .3s cubic-bezier(.6,.4,.05,.95) .6s;
    transform-origin: center;
}

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 2;
  animation-name: closeModal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;


  .modal_panel_1 {
    @include panel;
    transition-delay: 0s;
  }

  .modal_panel_2 {
    @include panel;
    transition-delay: .1s;
  }

  .modal_panel_3 {
    @include panel;
    transition-delay: .2s;
  }

  &.modal__active {
    animation: none;
    display: block;
    opacity: 1;
    pointer-events: all;

    .modal_panel_1 {
      opacity: .73;
      transform: scaleY(1);
      transition-delay: 0s;
    }

    .modal_panel_2 {
      opacity: .73;
      transform: scaleY(1);
      transition-delay: .1s;
    }

    .modal_panel_3 {
      opacity: .73;
      transform: scaleY(1);
      transition-delay: .2s;
    }
  }
}