@import 'src/styles/colors';

.logo {
  width: 100px;
  height: 40px;
  background-color: grey;
  border: 2px solid $black;
  text-align: center;
  color: $white;
  line-height: 40px;
  cursor: pointer;
}

.hamburgerWrapper {
  position: relative;
  height: 30px;
  width: 55px;

  & > div {
    position:absolute;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(89.42deg, $green1 9.38%, $orange-yellow 38.57%, $light-blue 66.67%, yellow 99.1%);
  }

  .hamburger_first {
    top: 5px;
  }

  .hamburger_second {
    bottom: 5px;
  }
}

.closeWrapper {
  position: relative;
  height: 30px;
  width: 55px;

  & > div {
    top: 15px;
    position:absolute;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(89.42deg, $green1 9.38%, $orange-yellow 38.57%, $light-blue 66.67%, yellow 99.1%);
  }

  .close_first {
    transform: rotate(25deg);
  }

  .close_second {
    transform: rotate(-25deg);
  }
}