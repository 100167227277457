@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/variables';

.question {
  margin-top: 40px;
  margin-left: 10px;
  height: 150%;
  padding-bottom: 80px;
  pointer-events: all;
  position: relative;

  @media (max-height: $min-height) {
    height: 600px;
    padding-bottom: 0;
  }


  &.question_mobile {
    padding-bottom: 40px;
    pointer-events: all;
    .titleWrapper {
      padding: 0 20px;
    }
    height: calc(100% - 40px - #{$crumbs-height});
  }

  .titleWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-transform: uppercase;
    padding-left: 80px;

    @media (max-height: $min-height) {
      padding-left: 30px;
    }

    .title {
      @include useFont(14px, 18px);
      letter-spacing: 2px;
      margin-right: 10px;
    }

    .age {
      @include useFont(16px, 20px);
      color: $green2;
    }
  }
}