@import 'src/styles/styles';

.animation {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: all;

  &.mobile_animation {
    canvas {
      position: fixed;
      top: 0;
      height: calc(100% + 80px) !important;
    }
  }

  @media (max-height: 700px) {
    canvas {
      position: fixed;
      top: 0;
      height: calc(100% + 80px) !important;
    }
  }
}