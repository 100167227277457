$black: #000000;
$white: #FFFFFF;
$light-blue: #63CCB9;
$light-blue2: #52557D;
$light-blue3: #0FD4FF;
$dark-blue: #0E0F1E;
$dark-blue2: #282943;
$dark-blue3: #16172C;
$dark-blue4: #040307;
$bg-grey: #44494C;
$grey: #BABABA;
$light-gray: #878BC2;
$dark-gray: #343651;
$dark-gray2: #464969;
$dark-purple1: #13102F;
$dark-purple2: #5452F6;
$light-purple: #282A47;
$light-purple2: #767AC7;
$yellow: #DCF58F;
$faux-dark: #1F2036;
$orange-yellow: #EDCD2A;
$light-green: #DCF58F;
$green1: #7DB725;
$green2: #B0DB26;
$dark-green: #59A437;
$red: #F94848;
$menu-color: #14112F;

