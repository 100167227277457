@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/styles';

.keynotePopupWrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em;

    h1 {
        @include useFont(2.5em, 1em, 400);
        text-transform: uppercase;
        text-align: center;
        background: linear-gradient(89.78deg, #B0DB26 8.56%, #DCF58F 70.78%, #EDCD2A 132.01%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: $min-width) {
            @include useFont(2em, 1.2em, 400);
          }
    }

    h2 {
        @include useFont(1.8em, 1em, 300);
        text-transform: uppercase;
        text-align: center;
        margin: 0;

        @media (max-width: $min-width) {
            @include useFont(1.5em, 1.2em, 400);
          }
    }

    p {
        @include useFont(1em, 1.2em, 100);
    }

    .heroImage {
        width: 80%;
        margin-top: -4.4em;
    }

    .mobileHeroImage {
        width: 50%;
        margin-top: -4.4em;
    }

    .headerText {
        position: absolute;
        top: 1.2em;
        width: 80%;
        
        h2 {
            text-align: center;
            background: linear-gradient(89.78deg, #B0DB26 8.56%, #DCF58F 70.78%, #EDCD2A 132.01%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
}