@import "src/styles/mixins";
@import 'src/styles/colors';
@import 'src/styles/variables';

.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  @include useFont(32px, 36px, 400);
  letter-spacing: 4.2px;

  .share {
    z-index: 2;
    margin-top: 80px;
    color: $white;
    @include useFont(12px, 12px);
    letter-spacing: 2.25px;

    @media (max-width: $min-width) {
      margin-top: 40px;
    }
  }

  .social {
    button {
      margin: 20px 10px;
      margin-bottom: 80px;
    }
  }

  .menuButton {
    margin: 20px;
    @include useFont(32px, 36px, 400);
    letter-spacing: 4.2px;
  }

  .privacy {
    display: flex;
    align-items: center;
    @include useFont(10px, 32px);
    letter-spacing: 0.2px;
    
    button {
      text-transform: uppercase;
    }

    & > div {
      margin: 0 10px;
      width: 1px;
      height: 70%;
      background-color: $white;
    }
  }
}

.modal_menu {
  @include useFont(28px, 30px, 400);
  justify-content: flex-start;
  height: calc(100% - #{$header-height});
  margin-top: $header-height;
  @include useOverflow();
  .privacy {
    flex-direction: column;
    margin-top: 50px;
    button {
      margin-bottom: 10px;
    }
  }
}

.modal_open {
  background-color: $dark-gray !important;
}

.horizontal_line {
  z-index: 1;
  height: 1px;
  width: 40%;
  max-width: 300px;
  margin: 0 auto;
}