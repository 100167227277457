@import 'src/styles/colors';
@import 'src/styles/variables';

@keyframes crumbAnimation {
  0% {
    background-color: $light-gray;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid $dark-blue;
    flex-shrink: 0;
    transition: background-color 1.2s;
  }
  66% {
    background-color: $light-gray;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid $dark-blue;
    flex-shrink: 0;
    transition: background-color 1.2s;
  }
  100% {
    background: $green2;
    border: 0.5px solid $dark-blue;
    width: 15px;
    height: 15px;
  }
}

.animationWrapper {
  position: absolute;
}
.crumbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  height: $crumbs-height;
  position: relative;
  pointer-events: all;
  margin-left: 80px;
  overflow: hidden;
  padding: 0 13px;

  @media (max-height: $min-height) {
    margin-left: 30px;
  }

  &.mobile {
    width: 100%;
    margin-left: 0px;

    .customHr {
      flex-shrink: 0;
    }
  }

  .customHr {
    top: 20px;
    position: absolute;
    height: 2px;
    right: 18px;
    background-color: $light-gray;
    z-index: 0;
    transition: width .8s;
    width: calc(100% - 36px);
  }

  .customHr_active {
    top: 20px;
    position: absolute;
    height: 2px;
    left: 18px;
    background: linear-gradient(43.28deg, $green1 30.72%, $orange-yellow 57.34%, $light-blue 82.3%, $yellow 110.59%);
    z-index: 0;
    transition: width 3.2s;
  }

  .crumb {
    background-color: $light-gray;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid $dark-blue;
    flex-shrink: 0;
    z-index: 1;
    position: relative;
    transition: all .8s;

    &.crumb_active {
      background: radial-gradient(50% 47.05% at 50% 50%, $yellow 0%, $green2 100%);
      border: 0.5px solid $dark-blue;
      transition: all .8s;
    }

    &.crumb_current {
      width: 15px;
      height: 15px;
    }

    &.crumb_animation {
      animation-name: crumbAnimation;
      animation-duration: 2.4s;
      animation-fill-mode: forwards;
    }
  }

  .crumbGradient {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(257.17deg, $light-green 25.06%, $dark-purple2 68.67%);
    filter: blur(3.46154px);
  }

  .crumbWrapper {
    width:15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    position: relative;
  }
}