@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.defaultButton {
  position: relative;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  outline: none;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  
}

.menuButton {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  svg {
    width: 30px;
    height: 30px;
  }

  &.open {
    svg > path {
    }
  }

  &.volume {
    margin-right: 20px;
  }
}

.buttonGroup {
  position: fixed;
  bottom: 40px;
  left: 80px;
  color: $dark-purple2;
  height: 60px;
  display: flex;
  align-items: center;

  &.mobileGroup {
    position: relative;
    bottom: 0;
    margin-top: 50px;
    padding-bottom: 10px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: flex-start;
  }

  .confirmButton {
    border: 2px solid;
    padding: 10px 5px;
    @include useFont(20px, 24px, 600);
  }

  .mobileButton {
    display: flex;
    justify-content: center;
  }

  @media (max-height: $min-height) {
    bottom: 10px;
    left: 30px;
  }
}

@mixin selectButton {
  padding: 10px;
  border-radius: 15px;
  background-color: $grey;
  margin: 5px;
  color: $black;
  @include useFont(14px, 18px);
  letter-spacing: 0.5px;
}
.thinkerVideo {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  position: relative;
  max-height: 400px;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }

  &.mobileVideo {
    height: 250px;
  }
}

.buttonNext {
  @include useFont(14px, 19px);
  color: $white;
  background-color: $dark-purple1;
  border: 1px solid $white;
  border-radius: 100px;
  padding: 15px 20px;
  position:relative;
  z-index: 1;
  letter-spacing: 3px;

  &.mobile {
    padding: 15px 16px;
    @include useFont(11px, 15px);
  }
}

.buttonLoadingExplore {
  @include useFont(14px, 19px);
  color: $white;
  background-color: $dark-purple1;
  border: 1px solid $white;
  border-radius: 100px;
  padding: 15px 20px;
  position:relative;
  z-index: 1;
  letter-spacing: 3px;

  &.mobile {
    padding: 15px 16px;
    @include useFont(11px, 15px);
  }
}

.buttonNextWrapper {

  &::after {
    @include useButtonAnimation();
    z-index: 0;
    border-radius: 100px;
    width:88%;
    height: 88%;
    top:6%;
    left:6%;
    transform: rotate(-4deg);
  }

  &:hover {
    .buttonNext {
      background-color: $light-purple;
    }
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    &::after {
      @include usePurpleYellowGradient(13.95%, 71.53%);
    }
  }

  &:active {
    .buttonNext {
      background-color: $dark-purple1;
    }
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    &::after {
      @include usePurpleYellowGradient(13.95%, 71.53%);
      transform: rotate(0deg);
    }
  }
  
}

.selectButtonWrapper {
  margin: 5px 0px;
  margin-right: 8px;

  .selectButton {
    position: relative;
    z-index: 1;
    background-color: $light-purple;
    border: 1px solid $light-gray;
    padding: 7px 30px;
    border-radius: 50px;
    @include useFont(14px, 18px);
    letter-spacing: 0.5px;
    color: $white;
    text-transform: uppercase;
  
    &.selectButton_active {
      color: $faux-dark;
      background: linear-gradient(17.52deg, $green1 46.88%, $orange-yellow 95.55%, $light-blue 142.41%, $yellow 196.48%);
      border: 1px solid $dark-green;
      box-shadow: 0px 0px 8px $light-gray;
    }
  }

  &::before {
    @include useButtonAnimation();
    z-index: 0;
    opacity: 0;
    width: 94%;
    height: 94%;
    left: 3%;
    top: 3%;
  }

  &:hover {
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    &::before {
      opacity: 1;
    }
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);

    .selectButton {
      background-color: $dark-gray2;
    }
  }

  &:focus {
    .selectButton {
      @include useBlueShadow();
    }
  }
}

.radioButtonContainer {
  
  .radioButton {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $dark-gray;
  
    &.radioButton_active {
      background: linear-gradient(23.61deg, $green1 44.84%, $orange-yellow 89.19%, $light-blue 158.28%, $yellow 222.49%);

      &::after {
        content: "";
        z-index: 2;
        position:absolute;
        top: calc(50% - 4px);
        right: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $faux-dark;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    &::before {
      content: "";
      @include useButtonAnimation();
      z-index: 0;
      opacity: 1;
    }
  }
}

.pencilButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0;
  top: -15px;
  left: -30px;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: $dark-gray;
  }
}

.socialButton {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: $dark-purple1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-gray;
  svg {
    width: 16px;
    height: 16px;
  }
  
  &::after {
    @include useButtonAnimation();
  }

  &:hover {
    background-color: $light-purple;
  }
}

.textarea {
  width: 100%;
  position: relative;
  border: none;
  background-color: $dark-gray;
  color: $white;
  border: 1px solid $dark-gray;
  border-radius: 10px;
  resize: none;
  padding: 10px 18px;
  height: 107px;
  outline: none;
  @include useFont(16px, 18px, 600);

  &:focus {
    @include useBlueShadow();
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $light-gray !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    font-style: italic;
  }

  &::placeholder {
    color: $light-gray !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    font-style: italic;
  }
}

.input {
  position: relative;
  border: none;
  background-color: $dark-gray;
  color: $white;
  border: 1px solid $dark-gray;
  border-radius: 10px;
  resize: none;
  padding: 10px 18px;
  width: 100%;
  outline: none;
  z-index: 1;
  @include useFont(16px, 18px, 600);
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $light-gray !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    font-style: italic;
  }

  &::placeholder {
    color: $light-gray !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    font-style: italic;
  }
  
  &.input_confirm {
    margin-left: 20px;
  }

  &:focus {
    @include useBlueShadow();
  }
}

.checkButtonWrapper {
  .checkButton {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $dark-gray;
    border: 1px solid $dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    &.checkButton_active {
      @include useYellowGreenGradient(44.84%, 89.19%, 158.28%, 222.49%);
    }
  }

  &:hover {
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    &::before {
      @include useButtonAnimation();
      z-index: 0;
      opacity: 1;
      right: 10px;
      width: calc(100% - 10px);
    }
  }
}



.record_button {
  margin-right: 20px;
  flex-shrink: 0;
  width: 67px;
  height: 67px;
  border-radius: 50%;
  background-color: $dark-blue2;
  display: flex;
  justify-content: center;
  align-items: center;

  &.error_button {
    opacity: .5;

    svg > path {
      fill: $white;
    }
  }

  svg {
    width: 20px;
    height: 37px;
  }

  &.disabled_button {
    background-color: $light-blue2;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  @include useFont(12px, 16px);
  margin-bottom: 8px;
  margin-top: 16px;
  text-transform: uppercase;
  text-align: left;
}

.tiny {
  font-size: 0.4em;
}

.submitInput {
  width: 100%;

  .title {

    .errors{
      text-transform: lowercase;
    }
  }

  &.inputError {
    input {
      border: 1px solid $red;
    }

    .title {
      color: $red;
    }
  }

  &.inputTouched {
    .title {
      color: $green2;
    }
  }
  

  .inputWrapper {
    position: relative;

    &:hover {
      box-shadow: 0 0 12px rgba(255,255,255, 0.01);
      .inputAnimation {
        opacity: 1;
      }
    }

    .inputAnimation {
      z-index: 0;
      position:absolute;
      width:100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 10px;
      opacity: 0;
  
      @include useBlueGradient(13.95%, 71.53%);
    }
  }


  input {
    &::placeholder {
      color: $light-gray;
    }
  }
}

.videoPlayButton {
  background-color: $dark-gray;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    margin: 0;
    width: 35px;
    height: 35px;
  }
}

.thinkerPlayButton {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $dark-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  svg {
    margin: 0;
    padding: 0;
    width: 35px;
    height: 35px;
  }
}

.required {
  &:after {
    content: " *";
    color: $green2;
  }
}

.required_before {
  &:before {
    content: "* ";
    color: $green2;
  }
}

.skipButton {
  margin-right: 15px;
  text-transform: uppercase;
  color: $light-gray;
  @include useFont(14px, 48px);
  border-radius: 100px;
  padding: 0 36px;

  &.mobile {
    padding: 0 30px;
    @include useFont(11px, 40px);
  }

  &:hover {
    background-color: $light-purple;
    border: 1px solid $light-purple;
  }

  &:focus {
    @include useBlueShadow();
  }

  &:active {
    background-color: $dark-purple1;
    border: 1px solid $light-gray;
  }
}

.horizontal_line {
  height: 1px;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.hrefLine {
  width: 100%;
  height: 1px;
  @include useYellowGreenGradient(44.84%, 89.19%, 158.28%, 222.49%);
}