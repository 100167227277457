@import 'src/styles/variables';
@import "src/styles/mixins";
@import 'src/styles/colors';
@import 'src/styles/styles';

.startPage {
  height: calc(100% - 2.5 * #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;

  @media (max-height: $min-height) {
    height: calc(100% - 2 * #{$header-height});
  }

  &.startMobile {
    height: calc(100% - #{$header-height});
    justify-content: flex-start;
    @include useOverflow();
    pointer-events: all;
  }

  .title {
    @include useFont(46px, 50px);
    letter-spacing: 4.2px;
    max-width: 800px;
    margin: 0 20px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    z-index: 1;

  }

  .description {
    @include useFont(20px, 24px);
    max-width: 800px;
    margin: 0 20px;
    text-align: center;
    margin-bottom: 20px;
    color: $grey;
    z-index: 1;
  }

  .startButton {
    flex-shrink: 0;
    width: 160px;
    height: 69px;
    color: $dark-purple2;
    border: 1px solid $dark-purple2;
    @include useFont(18px, 24px 600);
  }

  .aboutButton {
    margin-top: 35px;
    text-transform: uppercase;
    color: $green2;
    @include useFont(12px, 16px);
    cursor: pointer;
    pointer-events: all;
  }

  svg {
    margin-top: 40px;
    height: 18px;
    width: 18px;
  }

  @media (max-width: $min-width) {
    height: calc(100% - #{$header-height});
    
    .title {
      @include useFont(32px, 36px);
    }

    .description {
      @include useFont(15px, 24px);
      letter-spacing: 1px;
      margin-bottom: 30px;
    }
  }
}

.keynoteModal {
  div:first-of-type {
    max-width: 500px;
  }
}