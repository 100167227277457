@import 'src/styles/styles';
@import 'src/styles/colors';
@import 'src/styles/variables';

@mixin socialButtons {
  display: flex;
  justify-content: space-between;
}

@keyframes openFilter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeFilter {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.socialWrapper {
  @include socialButtons;
  position: fixed;
  left: 3em;
  bottom: 0;
  transition: opacity 1.2s, visibility 1.2s;
  opacity: 1;
  padding: 40px;
  z-index: 1;
  button {
    margin: 0 4px;
  }

  &.socialSmall {
    padding: 30px;
    padding-bottom: 10px;
  }
}

.audio {
  display: flex;
  align-items: center;
  z-index: 3;
  position: fixed;
  right: 0;
  bottom: 0;
  padding-bottom: 40px;
  padding-right: 40px;

  &.audioSmall {
    padding-bottom: 10px;
    padding-right: 30px;
  }
}

.disabled {
  visibility: hidden;
  opacity: 0;
}

.rightSocial {
  @include socialButtons;
  width: 120px;
  margin-right: 75px;
  z-index: 1;
  position: fixed;
  right: 60px;
  bottom: 40px;

  button {
    margin-right: 5px;
  }
}

.socialButtonWrapper {
  display: inline-block;

  button {
    margin: 0 6px;
  }
}

.exploreFooterContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  height: $header-height;
  align-items: flex-end;
  padding-bottom: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;

  button {
    z-index:1;
  }

  .exploreFooter_audio {
    margin-left: 15px;
  }

  .exploreFooter_voices {
    z-index: 1;
    animation-name: openFilter;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;

    &.exploreFooter_closeFilter {
      animation-name: closeFilter;
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
    }

    .voices_description {
      margin-top: 20px;
      color: $light-gray;
      text-align: center;
      text-transform: uppercase;
      @include useFont(12px, 14px);
    
      p {
        margin: 0;
        margin-left: 10px;
        display: inline-block;
        color: $green2;
        @include useFont(18px, 20px);
      }
    }
  }

  .exploreFooter_thinkers {
    z-index: 1;
    width: 300px;
  }

  .exploreFooter_socials {
    width: 300px;
    text-align: right;
  }

}

.thinkersImageContainer {
  z-index: 1;
  color: $white;
  text-transform: uppercase;
  @include useFont(18px, 20px);
  letter-spacing: 4px;

  .thinkersImages {
    display: flex;
    margin-bottom: 15px;

    @for $i from 0 through 3 {
      .image#{$i} {
        $index: calc(4 - #{$i});
        right: calc(#{$i}* 10px);
        z-index: $index;
      }
    }
    
    img {
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow:5px 5px 15px 5px #000000;
    }
  }
}

.buttonNext {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  animation-name: openFilter;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;

  &.buttonNextClose {
    animation-name: closeFilter;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }

  svg {
    stroke: $white;
  }
}