@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/styles';

.greeting {
  transition: opacity 1.2s, visibility 1.2s;
  opacity: 1;
  z-index: 4;
  pointer-events: all;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow:hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.greeting_close {
    visibility: hidden;
    opacity: 0;
  }
}

.video_buttonWrapper {
  position: absolute;
  bottom: 20px;
}

.videoWrapper {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}