@import "src/styles/mixins";
@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/styles';

.thinkers {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  pointer-events: all;
  height: calc(100% - 2 * #{$header-height});
  @include useOverflow();

  &.mobile {
    height: auto;
  }
  
  * {
    z-index: 1;
  }

  .instruction {
    margin-top: 40px;
    text-transform: uppercase;
    color: $white;
    @include useFont(12px, 20px);
    letter-spacing: 1.2px;

    .instructionWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 7px;

      .arrowRight {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid $white;
        margin-left: 5px;
      }

      .arrowLeft {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent; 
        border-right:3px solid $white;
        margin-right: 5px;
      }

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $white;
      }
    }
  }

  .title {
    @include useFont(24px, 32px);
    letter-spacing: 4.2px;
    margin-bottom: 17px;
  }

  .description {
    max-width: 700px;
    text-align: center;
    margin: 0 15px;
    @include useFont(15px, 24px);
    letter-spacing: 1px;
  }

  hr {
    width: 200px;
  }

  .thinkerWrapper {
    padding-top: 55px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    overflow-y: none;
    overflow-x: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {display:none;}
  }
}

// .horizontal_line {
//   width: 25%;
// }