@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.dropdown {
  position: absolute;
  top: 80%;
  border-left: 1px solid $dark-gray;
  border-right: 1px solid $dark-gray;
  border-bottom: 1px solid $dark-gray;
  border-radius: 0 0 10px 10px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  background-color: $dark-gray;
  overflow: hidden;
  padding-top: 20px;

  .dropdown_entry_wrapper {
    position: relative;

    .optionsWrapper {
      pointer-events: all;
      height: 100%;
      max-height: 250px;
      @include useOverflow();
    }

    .dropdown_hr {
      position: absolute;
      width: 96%;
      left: 2%;
      height: 1px;
      background-color: $light-gray;
      top: -10px;
    }
  }

  .option {
    padding: 10px 20px;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
    }
  }

  &.dropdown_up {
    bottom: 80%;
    top: auto;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 20px;
    border-top: 1px solid $dark-gray;
    border-radius: 10px 10px 0 0;

    .dropdown_entry_wrapper {
  
      .dropdown_hr {
        top: calc(100% + 10px);
      }
    }
  }

  &.dropdown_visible {
    display: none;
  }
}

.select {
  z-index: 1;
  position: relative;
  padding: 9px 20px;
  border: 1px solid $dark-gray;
  border-radius: 10px;
  text-align: right;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  background-color: $dark-gray;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  z-index: 1;

  .currentValue {
    max-width: 90%;

    &.selectPlaceholder {
      color: $light-gray;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      font-style: italic;
    }
  }

  svg {
    width: 12px;
    height: 12px;
    fill: $green2;
  }

  &.select_up {
    svg {
      transform: rotate(180deg);
    }
  }
}

.selectContainer {
  position: relative;
  box-sizing: border-box;
  pointer-events: all;

  .selectAnimation {
    z-index: 0;
    position:absolute;
    width:100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0;

    @include useBlueGradient(13.95%, 71.53%);
  }

  select {
    z-index:2;
    background-image:
      linear-gradient(45deg, transparent 50%, $green2 50%),
      linear-gradient(135deg, $green2 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em + 5px),
      calc(100% - 15px) calc(1em + 5px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;

    &:focus {
      background-image:
        linear-gradient(135deg, transparent 50%, $green2 50%),
        linear-gradient(45deg, $green2 50%, transparent 50%);
    }
    
    color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    height: 43px;

    &.select_disabled {
      position: absolute;
      bottom: 0;
      opacity: 0;
      height: 0px;
      z-index: 0;
      padding: 0;
      width: 0px;
      left: 50%;
    }
  }

  &:hover {
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    .selectAnimation {
      opacity: 1;
    }

  }

  &.selectActiveWrapper {
    &:hover {
      
      .selectAnimation {
        opacity: 0;
      }
    }

    .select {
      border: 1px solid $dark-gray !important;
    }
  }
}

.custom_disabled {
  display: none;
}

.textareaWrapper {
  position: relative;
  width: 80%;
  margin-top: 20px;
  display: flex;

  @media (max-width: $min-width) {
    width: 100%;
  }

  textarea {
    z-index: 1;
  }

  &:hover {
    box-shadow: 0 0 12px rgba(255,255,255, 0.01);
    &::before {
        content: "";
        position:absolute;
        width:calc(100% - 3px);
        height: calc(100% - 3px);
        top: 1.5px;
        left: 1.5px;
        border-radius: 10px;
    
        @include useBlueGradient(13.95%, 71.53%);
        transform: rotate(-1deg)
    }
  }
}