@import 'src/styles/styles';
@import 'src/styles/colors';
@import 'src/styles/mixins';

@keyframes opacityTrue {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes opacityTrue {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityFalse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes opacityFalse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.typeQuestion {
  margin-top: 20px;
  pointer-events: all;
  height: calc(100% - 30px);
  padding-bottom: 30px;
  padding-left: 80px;
  opacity: 0;
  transition: opacity 1s;

  @media (max-height: $min-height) {
    padding-left: 30px;
  }

  &.type_mobile {
    padding: 0 20px;
    padding-bottom: 20px;
    pointer-events: none;
  }

  &.typeAnimation {
    opacity: 1;
    // animation: opacityTrue 1s forwards;
    // -webkit-animation: opacityTrue 1s forwards;
  }

  &.typeConfirm {
    opacity: 0;
    // animation: opacityFalse 1s forwards;
    // -webkit-animation: opacityFalse 1s forwards;
  }

}

.grace_says {
  color: $light-gray;
  margin-bottom: 10px;
}

.inner_title {
  margin-top: 20px;
  text-transform: none;
  font-size: 16px;
}

.question_title {
  max-width: 600px;
  margin-bottom: 50px;
  @include useFont(17px, 24px);
  letter-spacing: 1px;

  &.title_under {
    margin-top: 40px;
  }

  &.completeTitle {
    color: $grey;
  }
}

.several_title {
  max-width: 600px;
  margin-bottom: 15px;
  
  &.completeTitle {
    color: $grey;
    @include useFont(14px, 16px);
  }
}

.pencilWrapper {
  position: absolute;
  left: -10px;

  @media (max-width: $min-width) {
    left: -3px;
  }
}

.selectButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 1;
  transition: opacity 1s;

  &.selectButtonsHidden {
    opacity: 0;
  }

  .button {
    @include selectButton;
  }

  .active_button {
    background-color: $black;
    color: $white;
  }
}

.label {
  margin-top: 20px;
  @include useFont(20px, 24px, 600);
}

.textareaWrapper {
  margin-top: 20px;
}

.inputWrapper {
  margin-top: 20px;
}

.rate_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  .select {
    width: 60px;
    padding: 10px 10px;
  }

  .select_answer {
    margin-left: 10px;
  }
}

.radio {
  margin-top: 30px;

  .radio_wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;


    .radio_container {
      display: flex;
      justify-content: flex-start;
      max-width: 300px;
      width: 100%;
      align-items: center;
    }

    .radio_button {
      width: 20px;
      height: 20px;
      background-color: $grey;
      border-radius: 50%;
      margin-right: 15px;

      &.radio_current {
        background-color: $dark-purple2;
      }
    }

    .radio_content {
      @include useFont(14px, 16px);
      max-width: 200px;
      width: 100%;
      margin: 0 10px;
    }
  }

  &.radio_one {
    .radio_container {
      max-width: 100%;

      .radio_content {
        max-width: 100%;
      }
    }
  }
}

.radio_confirm {
  transition: opacity 1s, visibility 1s;
  visibility: hidden;
  opacity: 0;
}

.radio_answer {
  text-align: left;
  margin-bottom: 15px;
  @include useFont(20px, 24px, 400);
  width: 70%;
}

.radio_greeting {
  @include useFont(36px, 40px);
  text-shadow: 2px 2px 2px rgba(206,206,206,0);
  margin: 0 40px;
  margin-bottom: 40px;
  width: 70%;

  @media (max-width: $min-width) {
    margin-left: 20px;
  }
}

.radio_name {
  @include useFont(20px, 24px, 600);
  color: $green2;
  margin: 0 40px;
  width: 70%;
}

.greeting {
  transition: opacity 4s, visibility 4s;
  position: absolute !important;
  flex-direction: column;
  text-align: left;
  opacity: 1;
  z-index: 2;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  justify-content: flex-start !important;
  padding-top: 12% !important;

  @media (max-width: $min-width) {
    align-items: flex-start;
  }

  &.greeting_close {
    visibility: hidden;
    opacity: 0;
  }
}

.audioWrapper {
  text-align: left;
  @include useFont(16px, 20px, 600);

  .audioDesktopWrapper {
    padding-right: 15px;
    .commentWrapper {
      margin-top: 20px;
      max-width: 529px;
      width: 100%;
    
      textarea {
        margin: 0;
      }
    }

    .question_mobileMessage {
      text-align: center;
      margin: 30px 0;
      max-width: 529px;
    }

    textarea {
      margin: 0;
      margin-top: 20px;
    }

    .leaveMessageButton {
      color: $dark-gray;
      margin-top: 20px;
      max-width: 529px;
      width: 100%;
      text-align: center;
    }
  }

  .textarea {
    max-width: 529px;
    width: 100%;
  }
}

.answersWrapper {
  position: relative;
}
