body {
  background-color: #141221;
  scroll-behavior: smooth;
  margin: 0;
  // height: 100vh;
  font-family: 'Geogrotesque', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;

  #root {
    height: 100%;
    background-color: #141221;
  }
}

* {
  box-sizing: border-box;
}

button, input, textarea {
  font-family: 'Geogrotesque', Arial, Helvetica, sans-serif;
}

button {
  cursor: pointer;
}

button, textarea, input, select {
  pointer-events: all;
}