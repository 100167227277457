@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/styles';

.submitPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  text-align: center;
  height: calc(100% - #{$header-height});
  color: $white;
  position: relative;

  @media (max-width: $min-width) {
    height: auto;
    width: 100%;
  }

  .submitContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    button {
      margin-right: 38px;
    }
  }

  .submitButton {
    padding: 5px 40px;

    &.submitError {
      cursor: inherit !important;
      &:hover {
        background-color: $dark-purple1 !important;
      }
    }
  }

  .checkButtons {
    margin-top: 24px;
    margin-bottom: 50px;

    .checkWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      margin-top: 12px;
      @include useFont(14px, 17px);
      letter-spacing: 0.3px;
  
    }
  }
  .check_terms {
    cursor: pointer;
    margin-left: 5px;
    color: $green2;
    text-decoration: underline;
    pointer-events: all;
    margin-left: 5px;
  }

  .audio {
    width: 100%;
    background-color: rgba(12, 14, 30, 0.8);
    border-radius: 10px;
    padding: 15px;
    align-items: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    color: $light-gray;

    .comment {
      text-align: left;
      margin-top: 10px;
      color: $white;
      @include useFont(14px, 20px);
      letter-spacing: 0.3px;
    }

    .audioButton {
      display: flex;
      align-items: center;

      .audio_change {
        text-align: left;

        .time {
          @include useFont(24px, 28px);
          color: $green2;
        }

        .audio_edit {
          @include useFont(12px, 16px);
        }
      }
    }

    .message {
      text-transform: uppercase;
      @include useFont(12px, 16px);
      margin-top: 22px;
    }
  }

  .formContainer {
    height: 100%;
    max-width: 1400px;
    width: 100%;
  }

  .thank {
    text-align: left;
    text-transform: uppercase;
    @include useFont(32px, 40px);
    margin-bottom: 16px;
    letter-spacing: 4.2px;
    margin-top: 25px;
  }

  .description {
    @include useFont(16px, 20px);
  }
}

.desktopCheckWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .checkWrapper {
    margin: 0;
  }
}

.mobileSubmit {
  pointer-events: all;
  width: 100%;
  padding-bottom: 50px;

  img {
    border-radius: 50%;
  }

  .thank {
    text-align: center;
  }

  .title {
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    text-align: left;

    &.title_comment {
      color: $white;
    }
  }

  .inputWrapper {
    width: 95%;
    margin: 0 auto;
  }

  .customSelect {
    width: 95%;
    margin: 0 auto;
  }

}

.desktopSubmit {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .hr {
    height: 70%;
    width: 1px;
    background-color: $white;
  }

  .description {
    width: 50%;
    padding-right: 77px;
    padding-bottom: 20px;
    @include useOverflow();
    pointer-events: all;

    .body {
      @include useFont(17px, 24px);
      letter-spacing: 1px;
      margin-bottom: 25px;
      text-align: left;
    }

    img {
      border-radius: 50%;
    }

  }

  .form {
    height: calc(100% - #{$header-height} - 40px);
    margin-top: 40px;
    padding-left: 110px;
    padding-right: 80px;
    padding-bottom: 20px;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    &::-webkit-scrollbar-track
    {
      border-radius: 50px;
    }

    &::-webkit-scrollbar
    {
      width: 5px;
      background-color: $dark-gray;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: $light-gray;
      height: 10%;
      border-radius: 50px;
    }

    pointer-events: all;

    .input_wrapper {
      margin-right: 10px;
      width: 100%;
    }

    .submit_wrapper {
      width: 100%;
    }

    .form_row {
      display: flex;
      
      @media (max-width: $min-width) {
        display: inline-block;
        width: 100%;
      }

      > * {
        flex:1;
      }
    }
    
  }

}

.form_title {
  margin: 25px 0;
  @include useFont(14px, 18px);
  text-align: left;
}

.messageButton {
  pointer-events: all;
  cursor: pointer;
}

.submitLoading {
  background-color: $dark-purple2;
}

.customSelect {
  .title {
    display: flex;
    justify-content: space-between;
  }

  .title_error {
    color: $red;
  }
  &.selectError {

    .title {
      color: $red;
    }

    .select {
      border: 1px solid $red;
    }
  }

  &.selectTouched {
    .title {
      color: $green2;

      &.title_comment {
        color: $white;
      }
    }
  }
}

.formRow {
  height: 100%;

  svg {
    height: 100%;
  }
}

.legalDisclaimer {
  text-align: left;
  @include useFont(14px, 20px);
  letter-spacing: 1.2px;
  margin-top: 60px;

  div {
    margin-top: 20px;
  }
}