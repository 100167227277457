@import 'src/styles/colors';
@import 'src/styles/mixins';

.pageWrapper {
  // height: 100%;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;

  &.scrollable {
    height: 100%;
  }

  &.aboutBackground {
    background-color: $bg-grey;
  }

  &.mobileWrapper {
    @include useOverflow();
    pointer-events: inherit;
  }

  .page {
    height: 100%;
    pointer-events: none;
  }
}