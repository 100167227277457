@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.voicesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
  width: 100vw;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  &.voicesMessage {
    padding-top: 40px;
    justify-content: flex-start;
  }

  .voices_navigatorWrapper {
    position: absolute;
    bottom: 40px;

    .voices_mobileShare {
      display: flex;
      margin-bottom: 20px;

      div {
        margin: 0 20px;
      }
    }

    &.voices_navigatorMobile {
      bottom: -80px;
    }
  }

  .voices_description {
    z-index: 1;
    color: $light-gray;
    text-align: center;
    text-transform: uppercase;
    // margin-top: 50px;
    @include useFont(16px, 20px, 600);
    position: absolute;
    bottom: 120px;

    div {
      height: 20px;
    }

    .voices_someone {
      height: 30px;
      color: $white;
      margin: 20px 0;
      @include useFont(26px, 30px, 600);
    }

    &.voices_mobileDescription {
      bottom: 40px;
      .voices_someone {
        margin: 12px 0;
        @include useFont(14px, 18px, 600);
      }
    }
  }

  .voices_userInfo {
    z-index: 1;
    text-transform: uppercase;
    text-align: center;
    width: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: break-word;


    .voices_name {
      color: $white;
      @include useFont(36px, 42px, 600);
    }
    .voices_country {
      margin-top: 10px;
      color: $green2;
      @include useFont(24px, 30px, 400);
    }
  }

  .voices_messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 120px);
    @include useOverflow();
    pointer-events: all;
    margin-top: 40px;

    .voices_userInfo {
      width: 350px;
    }

    .shareMessageContainer {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      margin-top: 12px;
    }
  }

  .voices_shareGroup {
    margin-top: 30px;

    button {
      margin: 0 30px;
    }
  }

  .voices_message {
    color: $white;
    max-width: 800px;
    padding: 0 30px;
    margin-top: 40px;
    @include useFont(22px, 34px);
  }

  .voices_buttonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    padding: 0 30px;

    .voices_buttonBlur {
      background-color: rgba(0, 0, 0, .15);
      backdrop-filter: blur(10px);
      border-radius: 50%;
      width: 280px;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .voices_playWrapper {
      position: relative;
      width: 350px;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;

      .voices_canvas {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .voices_playButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        text-transform: uppercase;

        svg {
          width: 50px;
          height: 50px;
        }

        p {
          color: $white;
          @include useFont(34px, 40px, 400);
          letter-spacing: 6px;
          margin-bottom: 0;
        }
      }
    }

    

    &.voices_buttonMobile {
      flex-direction: column;
      height: 100%;

      .voices_buttonBlur {
        width: 240px;
        height: 240px;
      }

      .voices_playWrapper {
        width: 80%;
      }

      .shareContainer {
        padding: 0;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
      }

      .voices_userInfo {
        margin-top: 20px;
        height: 80px;

        .voices_name {
          @include useFont(24px, 26px, 600);
        }

        .voices_country {
          @include useFont(20px, 24px, 400);
        }

      }

      .voices_playButton {
        svg {
          width: 40px;
          height: 40px;
        }
  
        p {
          @include useFont(30px, 34px);
        }
      }
    }

    .shareContainer {
      width: 310px;
      height: 150px;
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .share {
        height: 40px;
      }

      .voices_social {
        margin-top: 15px;
      }
    }
  }
}