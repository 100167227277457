@import 'src/styles/styles';
@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/variables';


.aboutWrapper {
  height: calc(100% - #{$header-height} - 50px);
  -ms-overflow-style: none;
  @include useOverflow();
  pointer-events: all;

  * {
    z-index: 1;
  }
}

.about {
  color: $white;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  justify-content: center;
  text-align: center;

  .about_title {
    margin-bottom: 30px;
    @include useFont(24px, 32px, 400);
    letter-spacing: 4.2px;
    text-transform: uppercase;
  }
  
  .description {
    text-align: left;
    margin-top: 30px;
    @include useFont(15px, 24px, 400);
    letter-spacing: 1px;
  }
}

.videoPlayButton {
  width: 75px;
  height: 75px;
  svg {
    margin-left: 5px;
    width: 40px;
    height: 40px;
  }
}