@import 'src/styles/variables';
@import "src/styles/mixins";
@import 'src/styles/colors';
@import 'src/styles/styles';
@import 'src/components/submitPage/submitPage.module';

.header, .footer {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;

  .headerScrollIcon{
    margin-top: 2em;
    p{
      margin-bottom: 1em;
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    margin-top: 40vh;

    @media (max-width: $min-width) {
      max-width: 95%;
    }
  }
  .headerFooterLinkContainer{
    margin-top: 15vw;
    margin-bottom: 2em;
    color: white;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    .check_terms {
      cursor: pointer;
      margin-left: 5px;
      color: $green2;
      text-decoration: underline;
      pointer-events: all;
      margin: 0 5px;
    }
  }
}

.footer{
  justify-content: center;
}


.footerImage{
  width: 60vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  opacity: 0.6;
}

.scrollPromptText{
  // width: 20vw;
  color: $green2;
  text-transform: uppercase;
}

h1 {
  @include useFont(3em, 1em, 400);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: left;
  color: white;
  margin: 0.3em;
}

h2 {
    @include useFont(2em, 1.2em, 400);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    text-align: left;
    margin-bottom: 0.5em;
    color: white;

    @media (max-width: $min-width) {
      @include useFont(1em, 1.2em, 400);
      letter-spacing: 0.1em;
      text-align: center;
    }
}

h3 {
  @include useFont(1em, 1.2em, 300);
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  color: white;

  @media (max-width: $min-width) {
    @include useFont(0.8em, 1em, 300);
    text-align: center;
  }
}

.green {
  color: $green2;
}

.keynoteFormContainer {
  margin-bottom: 0 !important;

  .form{
    margin-top: 1em !important;
    height: 60vh !important;
    max-height: 650px;
    max-width: 800px;
    padding: 0 80px;

    @media (max-width: $min-width) {
      padding: 0;
    }
  }
}

.submitContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;

  button {
    margin-right: 0 !important;
  }
}

.required_copy{
  font-size: 0.8em;
  text-align: right;
}

.keynoteCheckButtons{
  margin-bottom: 0 !important;
}

.submitPage{
  @media (max-width: $min-width) {
    padding: 0;
  }
}

.keynotePage {

  p {
    color: white;
    margin-bottom: 0.1em;
  }

  h3 {
    color: $green2;
  }
}

.imageSequence {  
  width: 100vw;
  height: 100vh;
  // opacity: 0.3;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: lighten;
  }
}

.contentWrap {
  display: "flex"; 
  flex-direction: "column";
  justify-content: "flex-start";
  align-items: "stretch";
  height: "100%";
  width:"100%";
}

.contentWrapMobile {
  display: "flex"; 
  flex-direction: "column";
  justify-content: "flex-start";
  align-items: "stretch";
  height: "100%";
  width:"100%";
  .defaultButton {
    width: '100%'!important;
  }
}