@import 'src/styles/styles';
@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.filterPage_wrapper {
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  max-width: 700px;
  padding: 0 60px;
  width: 100%;
  margin: 0 auto;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, .15);
  backdrop-filter: blur(10px);
  border-radius: 60px;

  * {
    z-index: 2;
  }

  &.filterPage_mobile {
    justify-content: flex-start;
    pointer-events: all;
    @include useOverflow();

    .filterPage_title {
      text-align: center;
    }
  }

  form {
    width: 100%;
  }

  .filterPage_title {
    text-align: left;
    width: 100%;
    @include useFont(36px, 40px);
    letter-spacing: 2px;
    margin-bottom: 20px;
  }
}

.totalCount {
  height: 10px;
  margin-top: 20px;
  color: $green2;
  
  &.totalError {
    color: $white;
  }
}

.buttonWrapper {
  margin-top: 40px;
  height: 40px;

  &.buttonMobile {
    height: 55px;
    margin: 0;
    position: absolute;
    bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 80px);

    .filterCloseButton {
      padding: 0;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }

  .buttonNext {
    height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 40px;
    margin-right: 20px;
  }
  
  .skipButton {
    height: 38px;
    display: flex;
    align-items: center;
  }
}
