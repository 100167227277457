@import "src/styles/mixins";
@import 'src/styles/colors';
@import 'src/styles/styles';

.thinker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 70px;
  flex-shrink: 0;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: -1px -1px 37px 19px rgba(255,255,255,0.22);

    &:hover {
      opacity: 0.8;
    }
  }

  .text {
    margin-top: 29px;
    margin-bottom: 12px;
    color: $white;
    @include useFont(20px, 26px);
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .voiceButton {
    @include useFont(12px, 16px);
    letter-spacing: 20%;
    text-transform: uppercase;
    color: $green2;
  }
}

.thinkerPlayButton {
  width: 70px;
  height: 70px;

  svg {
    margin: 0;
    margin-left: 5px;
    padding: 0;
    width: 35px;
    height: 35px;
  }
}

.videoPlayButton {
  width: 75px;
  height: 75px;
  svg {
    margin-left: 5px;
    width: 40px;
    height: 40px;
  }
}

.name {
  margin: 20px 0;
  @include useFont(20px, 24px, 600);
  text-transform: uppercase;
}

.thinkerDescription {
  @include useFont(16px, 20px);
  text-align: left !important;
}