@import 'src/styles/variables';
@import 'src/styles/mixins';

.desktopQuestion {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  position: relative;

  &.desktopMobile {
    .question {
      padding-bottom: 60px;
      margin-top: 0px;
      height: 100%;
    }

    .overflowWrapper {
      height: 100%;
    }
  }

  .overflowWrapper {
    pointer-events: all;
    @include useOverflow();
    height:calc(100% - #{$crumbs-height});
  }

  .question {
    width: 50%;
    height: calc(100% - 40px);
    margin-top: 40px;
    overflow: hidden;
  }
  
  .animationImage {
    width: calc(50% - 240px);
    margin-right: 80px;
    margin-left: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    pointer-events: none;

    canvas {
      width: 100% !important;
      height:100% !important;
    }

    img {
      height: auto;
      max-height: 80%;
      max-width: 80%;
      height: 100%;
      width: 100%;
    }
  }
}