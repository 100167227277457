@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/styles';

.wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  color: $white;
  top: 0;
  transform:translateX(100%);
  z-index: 4;
  &.wrapper__active {
    opacity: 1;
    transform:translateX(0)
  }

  .body {
    background-color: $dark-blue4;
    max-width: 700px;
    width: 100%;
    padding: 30px 30px;
    position: relative;
    @include useOverflow();
    overflow: visible;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;

    &::before {
      @include useButtonAnimation();
      border-radius: 20px;
      width:100%;
      height: 100%;
      top:0%;
      left:0%;
      // transform: rotate(-4deg);
    }

    @media (max-width: $min-width) {
      position: absolute;
      width:100%;
      height: 100%;
      top:0%;
      left:0%;
      justify-content: start;
      &::before {
        background: none;
      }
    }
  }

  .mobileBody {
    max-width: 100vw;
  }

  // .closeButton {
  //   margin-bottom: 20px;
  //   text-align: right;
  //   @include useFont(20px, 24px, 600);
  //   cursor: pointer;
  // }

  .closeButtonContainer {
    width: 100%;
    text-align: right;
    margin-bottom: 1em;
  }
}

.videoPlayButton {
  width: 75px;
  height: 75px;

  svg {
    width: 65px;
    height: 65px;
  }
}
