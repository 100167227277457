@import 'src/styles/variables';
@import "src/styles/mixins";
@import 'src/styles/colors';
@import 'src/styles/styles';

.mainContainer {
    height: calc(100% - 2 * #{$header-height});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    .innerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 80%;
        @include useOverflow();

        * {
            z-index: 1;
            flex-shrink: 0;
          }

        > p {
            text-align: center;
            margin-bottom: 0.5em;
            color: white;
        }
    }

    .check_terms {
        cursor: pointer;
        margin-left: 5px;
        color: $green2;
        text-decoration: underline;
        pointer-events: all;
        margin: 0 5px;
    }
}

.plink{
    margin-top: 2em;
    text-transform: uppercase;
}
.check_terms {
    cursor: pointer;
    margin-left: 5px;
    color: $green2;
    text-decoration: underline;
    pointer-events: all;
    margin: 0 5px;
}