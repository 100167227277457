@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/styles';

.loaded {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 1;
  transition: opacity 2s;
  text-align: center;

  &.disabled {
    opacity: 0;
  }

  .loaded_background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .loaded_circle {
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 150px);
  }
}

.user_info {
  color: $white;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  @include useFont(16px, 20px);
  letter-spacing: 2px;
  pointer-events: none;

  .fullName {
    margin-bottom: 15px;
  }

  .location {
    color: $green1;
  }

}